export const getChannelName = (id: string) => {
switch(id) {
    case 'uhEma2t9BbaheoAuw':
        return 'CO (old)';
    case 'DFZWWrWKHszM7jfvz':
        return 'CO (new)';
    case 'jBHt9DioNbRy6cFyR':
        return 'CO (Private)';
    case 'GpdKpaaK4L7YrL6DY':
        return 'Announcements';
    case 'vtLoAt5utf7maSNbi':
        return 'Community Preparedness Commo';
    case 'uPidjx55ZxHRPTFyn':
        return 'Community Preparedness Adv. Commo';
    case 'umK83hWQexYsdWAS6':
        return 'Prepping';
    case 'nq4J7zrmmCeLeghqL':
        return 'Radio';
    case 'MgqNrvSQTeCsFo6E4':
        return 'LA';
    case '4ZmYbBXpzMxiq7CAe':
        return 'TX (new)';
    case 'CQ5Hpp9k92bphMqWf':
        return 'TX (old)';
    case 'enutHHPTjxQQ8XbQk':
        return 'CT';
    case '4nGi2y93gTHGRokeB':
        return 'DE';
    case 'dcEghQieuotDTiiif':
        return 'Plandemic';
    case 'oQ4pem75SvyuYxPC2':
        return 'NJ (old)';
    case 'wsPzGseZjJ2mH7XMK':
        return 'NJ (new)';
    case 'CrsWftFSYmriERKXb':
        return 'HI'
    case 'EZispH4LkpgyrhkWH':
        return 'PA (old)';
    case 'Mz2xscGHEy9CDCduK':
        return 'PA (new)';
    case 'Ke2Jnsh3WHfT6A2x6':
        return 'Server';
    case 'dAqJ5YixXtXtWJJDs':
        return 'VT (old)';
    case 'hzPjnqMhZWLNEcsLD':
        return 'VT (new)';
    case 'cR2DgA6eYmuTDrGSr':
        return 'VA';
    case '5HGtqtWdGj6WmjdWx':
        return 'WV';
    case 'pe57r38ZER2LHwzWS':
        return 'SC (new)';
    case 'tsqQABdW2Atv6hcfZ':
        return 'FL (old)';
    case 'zr2sJ6Pw8DCEncZ9s':
        return 'FL (new)';
    case 'Wbfoos6EXv3JcYdmx':
        return 'ID';
    case 'wXgcwSB9mizMdvfjn':
        return 'MI (old)';
    case '8Sh7W3L5ohauP2ujn':
        return 'MI (new)';
    case 'KmyDWPZciQWGWjkxD':
        return 'Guns';
    case 'dsh3YtcSMmrkXzWvS':
        return 'Firearms - State \u0026 Federal Legislation';
    case 'FHJgFgeio7rzcZs3z':
        return 'IA (old)';
    case 'tQ5JPSyP7P7Rp8SbN':
        return 'IA (new)';
    case '6eT2QumsvR3TWj3K5':
        return 'OK (old)';
    case '6tjPKNTHePRsiNq6c':
        return 'OK (new)';
    case 'NBqfkxYuHXpNeDbdc':
        return 'DC';
    case 's4ddn8CFZ8YNYEXL3':
        return 'Main';
    case 'mS758X9wyQjqpuGJT':
        return 'ME';
    case 'uDXdzGuyMaqKXQMt5':
        return 'Bible';
    case 'vB8gz4tDnRcajkGei':
        return 'MA (old)';
    case 'am74cP8xwmCJYw4y7':
        return 'MA (new)';
    case 'pncp4oNiBpPW7Drhf':
        return 'MA 2';
    case 'STDRc5sLabPHYiRBv':
        return 'Community Preparedness';
    case 'R5CWm49nScNneHu6v':
        return 'MT (old)';
    case 'vvDnm5riDCbaJTgrm':
        return 'MT (new)';
    case 'jc3mSmZfWKxtc3whT':
        return 'TX (Private)';
    case '7Q7EYgwkj58yZwpJW':
        return 'WA (old)';
    case 'yNsZuQLzTf7hXoFD3':
        return 'WA (new)';
    case '4k5ktdvJoAQLG7A4z':
        return 'RI';
    case 'BtwSD4s6Kf7Bioxjg':
        return 'OH (old)';
    case 'KNCwGTwzvgHxHkH6c':
        return 'OH (new)';
    case 'nDKPQRxfSsG54EaCe':
        return 'WI (old)';
    case '2rR6mwqyGcsq6LmSH':
        return 'WI (new)';
    case 'A9i9v9xL3M2NEXhBL':
        return 'AZ (old)';
    case 'W5vuR6dqHL9LAzStb':
        return 'AZ (new)';
    case '2ecjjboRYc8Ayuvyw':
        return 'CA (old)';
    case 'x4HYsJRxcTC7RJ2gi':
        return 'CA (new)';
    case 'G6G57FkbD9hzpvGwk':
        return 'GA (old)';
    case 'wTKrqDZQ2m4ahkRK5':
        return 'GA (new)';
    case 'XnZZfHgqsoxvk3KKh':
        return 'MD (old)';
    case 'WYMZYH3g9QujkeKg8':
        return 'MD (new)';
    case 'yqgEWT4Mmnq54pdpJ':
        return 'AL (old)';
    case 'nevX6kpkLYT3uGfRn':
        return 'AL (new)';
    case 'etewSTWdTHRjxwBK9':
        return 'NV (old)';
    case 'fxbivgyZX99tsYFMK':
        return 'AL';
    case 'niMkCBiGiXYFbBwPM':
        return 'AR';
    case '67oHYTJyTJnvk5FMH':
        return 'TN (old)';
    case 'XJPBRGKBt57si5FSt':
        return 'TN (new)';
    case 'JDnJycs6BjZxBw2ga':
        return 'SC (old)';
    case 'CWj6SxW78CxZ3D7fH':
        return 'Gear';
    case 'H62RSuuC6XD7ctzog':
        return 'KS (old)';
    case '8iEcD6v4aj9M58rkY':
        return 'KS (new)';
    case 'D6oJThToAdqroimX2':
        return 'OR';
    case 'Eri8SeNvs6Xx5eFWx':
        return 'NE (old)';
    case 'jaC76GfunB9BeezYu':
        return 'NE (new)';
    case 'RFi2z7dSkM7hsXn4h':
        return 'WY (old)';
    case 'tCH3j2z7rfzFeG3HX':
        return 'WY (new)';
    case 'HGFCnfH2SW9arLd8E':
        return 'Mods';
    case 'szeQhMMbqFGXF6MTP':
        return 'NY (old)';
    case 'kL5LTav99nYMxM64g':
        return 'NY (new)';
    case 'Tu5nEoLZJmmzNM2ym':
        return 'MO (old)';
    case 'azmtGSYveMTkCQrAA':
        return 'MO (new)';
    case 'fo4cWMJGWvvWNjpkj':
        return 'NM';
    case '7gRPdYseYNvNoicgq':
        return 'MI';
    case 'tYLgZKkxo2tKF7kTc':
        return 'NH (old)';
    case 'E6yT4qpjs6wHd5PGf':
        return 'NH (new)';
    case 'ntqeZh6hjEqHYk4Zj':
        return 'NH (Private)';
    case 'wLH7YKNdEQkzhQmxB':
        return 'MN (old)';
    case 'tBQq55KatybnxREMn':
        return 'MN (new)';
    case '5ugMZyhwrC4u3jmSH':
        return 'IL (old)';
    case 'Pd3TuERia6wPiDdPr':
        return 'IL (new)';
    case 'ggH9de5XHdQdANRJN':
        return 'IN (old)';
    case 'P9TBgjZMeESDvnSzD':
        return 'New England';
    case '4hg4vwJCR4NugAJ5W':
        return 'Ham Radio';
    case 'kD78H5gKbBDDKPLWu':
        return 'SHTF Preparations';
    case 'pN3YPpbfQRaqtfJ6o':
        return 'Shooting';
    case 'dCA2tQmnkLuTwDExS':
        return 'Reloading';
    case 'rk6ZqikPbX5dSSG5v':
        return 'ND';
    case 'nD6bAvtYtMZs9twKi':
        return 'SD';
    case 'dckxeekrMnDYjsv5T':
        return 'NC';
    case 'ZZnCkbgTxPEn8ssZ9':
        return 'UT (old)';
    case 'CujDZeo578vjP4L7e':
        return 'UT (new)';
    case 'GjLqFzhQ5hWtSqm2T':
        return 'KY (old)';
    case 'zt8BmgLrHadxEsSzG':
        return 'KY (new)';
    case 'L3fRmw6ai5rurX3xNLciQ76Wey7HcXAwJ7':
        return 'Phantom';
    case 'fDu7PZ28EX6qrcXHB':
        return 'Prayer';
    case 'nEJ95gRtSXqjs56RW':
        return 'Investments';
    case 'GGnJcPzKpCmPKrHtQ':
        return 'Patriots';
    case 'RJtDN64EjBTYtwEtD':
        return 'Firearms';
    case '7r3CtCLZ3dWCGeCgd':
        return 'Ammo';
    case 'rFJePTTRGYyWwH2gy':
        return 'anyone in the Tuscon area?';
    case '5Fd6BJNYJjrcCLkAr':
        return 'ssgtman';
    case 't7FDR4JwigqcnWPYt':
        return 'Greetings from Southern Arizona.  perfect weather to ride all year round';
    case 'GRz2AckCfaqnSRg7h':
    case 'YWMKzYEJH8rGa5kbx':
        return 'Hello';
    case 'QswPwReKsXXinvhH9':
        return 'SHTF Emergency Contacts List'
    default:
        return `Private Chat`;
}
}